import { Component } from '@angular/core';
import { CommmonUtil } from '../utils/common.util';

@Component({
  selector: 'my-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  currentYear: number;
  
  constructor(private commonUtil: CommmonUtil ) {
    this.currentYear = this.commonUtil.getCurrentYear();
  }
}

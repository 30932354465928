.popup-menu {
    position: fixed; 
    justify-content: center;
    align-items: center;
    border-radius: 8px;
	width: 243px; 
    display:none;
    flex-direction: column;
    background-color: white !important;
	box-shadow: 0px 2px 8px rgba(46, 47, 53, 0.10);
    z-index: 9999;
    margin:32px 0 0 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    span {
        color:#2E2F35;
        font-size: 14px;
        font-weight: 400;
    }
    a:first-child {
        padding: 16px 16px 8px 16px;
    }
    a:last-child {
        padding: 8px 16px 16px 16px;
    }
    a {
        display: flex;
        text-decoration: none;
        padding: 6px 16px;
        align-items: center;
    } 
    a > * + * {
        margin-left: 16px;
    }
    .popup-menu a span:first-child{
        font-size: 24px;
    }
    a:hover {
        padding-left: 13px;
        background-color: #FAF5FF !important; 
        border-left: 4px #7A27C9 solid;
        margin-left: -1px;
    }
    a > .material-symbols-outlined{
        font-size: 24px;
        color: #9094A0;
    }
    a:hover > .material-symbols-outlined{
        color: #7A27C9;
    }
}
.menu-icon{
    cursor: pointer;
    display: flex;
}
.popup-menu > .flex {
    z-index: 999; 
}
.space-y-4 > * + * {
    margin-top: 8px;
}
.backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    display: none;
}